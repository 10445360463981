import React from 'react'
const Footer = () => {
  return (
    <footer className="footer">
      <div>
        <p>&copy;{new Date().getFullYear()} All rights reserved.</p>
      </div>
    </footer>
  )
}

export default Footer
