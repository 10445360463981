import React from 'react'
import styled from 'styled-components'
import About from './About'
import Recent from './Recent'
import BannerCategories from './BannerCategories'
import BlogIntro from './BlogIntro'
const Banner = () => {
  return (
    <Wrapper>
      <BlogIntro />
      <BannerCategories />
      <About />
      <Recent />
    </Wrapper>
  )
}

const Wrapper = styled.aside`
  display: grid;
  grid-template-columns: 95%;

  justify-content: center;
  row-gap: 1rem;

  @media (min-width: 576px) {
    grid-template-columns: 95%;
    & {
      grid-template-columns: repeat(auto-fit, 200px);
      column-gap: 3rem;
    }
  }
  @media (min-width: 992px) {
    grid-template-columns: 90%;
    & {
      grid-template-columns: repeat(auto-fit, 200px);
      column-gap: 3rem;
    }
  }
`

export default Banner
