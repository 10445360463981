import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const Links = ({ styleClass, children }) => {
  return (
    <ul className={styleClass}>
      <li>
        <Link to="/" className="page-link">
          <NavText>ホーム</NavText>
        </Link>
      </li>
      <li>
        <Link to="/blog" className="page-link">
          <NavText>投稿一覧</NavText>
        </Link>
        {children}
      </li>
      {/* <li>
        <Link to="/post" className="page-link">
          Post
        </Link>
      </li> */}
      <li>
        <Link to="/contact" className="page-link">
          <NavText>お問い合わせ</NavText>
        </Link>
      </li>
    </ul>
  )
}

export default Links

const NavText = styled.span`
  font-family: 'Noto Sans JP', sans-serif;
`
