import React from 'react'
import {
  SocialLinksTakahira,
  SocialLinksShigyo,
} from '../../constants/socialLinks'
import { StaticImage } from 'gatsby-plugin-image'
import Title from './Title'
import styled from 'styled-components'

const About = () => {
  return (
    <Wrapper>
      <Title title="管理人" />
      <Author>
        <ProfPhotos>
          <StaticImage
            src="../../assets/profileImgTakahira.png"
            layout="fixed"
            width={100}
            height={100}
            alt="author"
            className="profImg"
          />
          <p className="myName">Takahira</p>
        </ProfPhotos>
        <p>
          AIとデータ可視化を専門にやっています。個人HPは
          <a href="https://kentarotakahira.com">こちら。</a>
        </p>

        <SocialLinksTakahira styleClass="banner-icons"></SocialLinksTakahira>
      </Author>

      <Author>
        <ProfPhotos>
          <StaticImage
            src="../../assets/profileImgShigyo.png"
            layout="fixed"
            width={100}
            height={100}
            alt="author"
            className="profImg"
          />
          <p className="myName">Shigyo</p>
        </ProfPhotos>
        <p>
          データ可視化とXRを利用したHuman-Computer Interactionに興味があります。
        </p>

        <SocialLinksShigyo styleClass="banner-icons"></SocialLinksShigyo>
      </Author>
    </Wrapper>
  )
}
const ProfPhotos = styled.div`
  .profImg {
    margin: auto;
    /* margin-bottom: 0.5rem; */
  }

  .myName {
    margin: auto;
    margin-top: 0.5rem;
    font-size: 1.1rem;

    text-align: center;
  }
  margin-bottom: 0.5rem;
`

const Author = styled.div`
  margin-bottom: 2rem;
`
const Wrapper = styled.div`
  text-align: left;
  p {
    font-size: 0.875rem;
    color: var(--clr-grey-5);
    font-family: 'Noto Sans JP', sans-serif;
  }
  .img {
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  p a {
    text-decoration: none;
    color: var(--clr-grey-5);
  }
  p a:hover {
    color: hotpink;
  }
`

const SelectedArtWork = styled.div`
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
  .left {
    justify-self: flex-start;
  }
  .right {
    justify-self: flex-end;
  }
`
export default About
