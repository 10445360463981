import React from 'react'
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaInstagramSquare,
} from 'react-icons/fa'
import { SiGooglescholar } from 'react-icons/si'

import logo from '../assets/observable-logo.svg'

const SocialLinksTakahira = ({ styleClass }) => {
  return (
    <ul className={styleClass}>
      <li>
        <a href="https://twitter.com/cocomils11">
          <FaTwitterSquare className="social-icon twitter-icon"></FaTwitterSquare>
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/cocomils11/">
          <FaInstagramSquare className="social-icon instagram-icon"></FaInstagramSquare>
        </a>
      </li>
      <li>
        <a href="https://observablehq.com/@kentarotakahira">
          <img
            src={logo}
            alt="observable logo"
            className="social-icon observable-icon"
          />
        </a>
      </li>
      <li>
        <a href="https://scholar.google.com/citations?hl=en&user=CoPUduUAAAAJ">
          <SiGooglescholar className="social-icon google-scholar-icon"></SiGooglescholar>
        </a>
      </li>
    </ul>
  )
}

const SocialLinksShigyo = ({ styleClass }) => {
  return (
    <ul className={styleClass}>
      <li>
        <a href="https://twitter.com/kenglwo">
          <FaTwitterSquare className="social-icon twitter-icon"></FaTwitterSquare>
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/keentoart/">
          <FaInstagramSquare className="social-icon instagram-icon"></FaInstagramSquare>
        </a>
      </li>
      <li>
        <a href="https://www.semanticscholar.org/author/Kento-Shigyo/1500661165">
          <SiGooglescholar className="social-icon google-scholar-icon"></SiGooglescholar>
        </a>
      </li>
    </ul>
  )
}
export { SocialLinksTakahira, SocialLinksShigyo }
