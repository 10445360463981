import React from 'react'
import Title from './Title'
import styled from 'styled-components'

const BlogIntro = () => {
  return (
    <Wrapper>
      <Title title="ブログについて" />
      <p>
        データビジュアライゼーション（データ可視化）に関する最新事例や活用例を日本語で紹介しています。
      </p>
      <p className="sec">
        チャートの種類や、デザインの考え方、ソフトウェアを用いた実装例についても取り上げています。
      </p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: left;
  p {
    font-size: 0.875rem;
    color: var(--clr-grey-5);
    font-family: 'Noto Sans JP', sans-serif;
  }
  .sec {
    margin-bottom: 0rem;
  }
`

export default BlogIntro
